const PLATFORM = process.env.VUE_APP_PFID
const ENV = process.env.NODE_ENV || 'development'
const CENTER_URL = ''

import config from './config.json'

const EnvConfig = config

export default {
  ENV,
  PLATFORM,
  CENTER_URL,
  ...EnvConfig[PLATFORM][ENV],
}
